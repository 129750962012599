<template>
    <router-view class="light" />
</template>
<script>
    export default {
    }
</script>

<style lang="scss">
    @import 'assets/css/all.css'; //폰트어썸
    @import '~bootstrap/dist/css/bootstrap.min.css'; //부트스트랩
    @import '~bootstrap-vue/dist/bootstrap-vue.css'; //부트스트랩
    @import 'assets/css/custom-bootstrap.scss';
    @import 'assets/css/common.scss';

    @import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
    @import url('https://fonts.googleapis.com/css2?family=Lobster&family=Noto+Sans:wght@300;400;500;600;700&display=swap');

    #app {
        font-family:'Noto Sans', 'Noto Sans KR', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
        min-height: 100vh;
        position: relative;
    }
    .modal{
        display: block;
        position: fixed;
        opacity: 0;
        transition: all 0.3s;
        pointer-events: none;

        .dimm{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,.3);
        }
        
        .modal-content{
            pointer-events: none;
            border: 0;
        }
    }
    .modal.show{
        opacity: 1;
        pointer-events: initial;
        
        .modal-content{
            pointer-events: initial;
        }
    }
.avatar{
    object-fit: cover;
    width: 100%;
    height: 100%;
}
</style>
