import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SignView from '../views/SignView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home-view',
    redirect: '/home',
    component: HomeView,
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/home/Home.vue')
      },
      {
        path: '/neighborhood',
        name: 'Neighborhood',
        component: () => import(/* webpackChunkName: "neighborhood" */ '../views/home/Neighborhood.vue')
      },
      {
        path: '/recommend',
        name: 'Recommend',
        component: () => import(/* webpackChunkName: "recommend" */ '../views/recommend/Recommend.vue')
      },
      {
        path: '/like',
        name: 'Like',
        component: () => import(/* webpackChunkName: "like" */ '../views/like/Like.vue')
      },
      {
        path: '/partner-profile/:id',
        name: 'PartnerProfile',
        component: () => import(/* webpackChunkName: "like" */ '../views/like/PartnerProfile.vue')
      },
      {
        path: '/talk',
        name: 'Talk',
        component: () => import(/* webpackChunkName: "like" */ '../views/like/Matching.vue')
      },
      {
        path: '/talk/:idx',
        name: 'Chat',
        component: () => import(/* webpackChunkName: "like" */ '../views/like/Chat.vue')
      },
      {
        path: '/mypage',
        name: 'Mypage',
        component: () => import(/* webpackChunkName: "like" */ '../views/mypage/Home.vue')
      },
      {
        path: '/mypage/profile',
        name: 'Profile',
        component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/Profile.vue')
      },
      {
        path: '/mypage/setting',
        name: 'Setting',
        component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/Setting.vue')
      },
      {
        path: '/mypage/push',
        name: 'Push',
        component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/Push.vue')
      },
      {
        path: '/mypage/filter',
        name: 'Filter',
        component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/Filter.vue')
      },
      {
        path: '/cs/notice',
        name: 'Notice',
        component: () => import(/* webpackChunkName: "cs" */ '../views/cs/Notice.vue')
      },
      {
        path: '/cs/notice/:idx',
        name: 'NoticeDetail',
        component: () => import(/* webpackChunkName: "cs" */ '../views/cs/NoticeDetail.vue')
      },
      {
        path: '/cs/inquiry/list',
        name: 'InquiryList',
        component: () => import(/* webpackChunkName: "cs" */ '../views/cs/InquiryList.vue')
      },
      {
        path: '/cs/inquiry/write',
        name: 'InquiryWrite',
        component: () => import(/* webpackChunkName: "cs" */ '../views/cs/InquiryWrite.vue')
      },
      {
        path: '/cs/faq',
        name: 'FAQ',
        component: () => import(/* webpackChunkName: "cs" */ '../views/cs/Faq.vue')
      },
    ]
  },
  {
    path: '/sign',
    name: 'sign-view',
    component: SignView,
    children: [
      {
        path: '/intro',
        name: 'Intro',
        component: () => import(/* webpackChunkName: "sign" */ '../views/sign/Intro.vue')
      },
      {
        path: '/verify-phone',
        name: 'VerifyPhone',
        component: () => import(/* webpackChunkName: "sign" */ '../views/sign/VerifyPhone.vue')
      },
      {
        path: '/set-profile',
        name: 'SetProfile',
        component: () => import(/* webpackChunkName: "sign" */ '../views/sign/SetProfile.vue')
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
