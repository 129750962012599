<template>
    <div id="Nav" class="position-fixed bottom-0 w-100 bg-body bg-white zindex-10">
        <div class="my-1">
            <ul class="d-flex justify-content-around align-items-center">
                <li>
                    <router-link to="/home">
                        <div class="lh-1 fs-5 mb-1">
                            <i class="far fa-home-alt"></i>
                        </div>
                    </router-link>
                </li>
                <li>
                    <router-link to="/recommend">
                        <div class="lh-1 fs-5 mb-1">
                            <i class="far fa-sparkles"></i>
                        </div>
                    </router-link>
                </li>
                <li>
                    <router-link to="/like">
                        <div class="lh-1 fs-5 mb-1">
                            <i class="far fa-heart"></i>
                        </div>
                    </router-link>
                </li>
                
                <li>
                    <router-link to="/talk">
                        <div class="lh-1 fs-5 mb-1">
                            <i class="far fa-comment"></i>
                        </div>
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default { 
    data(){
        return{
            show: true,
        }
    },
    created() {
    },
    methods:{
        GotoMove(path){
            this.$router.push({path})
        }
    }
}
</script>

<style lang="scss" scoped>
    #Nav{
        // box-shadow: 0 0px 20px rgba(125, 125, 125, 0.1);
        bottom: 0;
        left: 0;

        ul{
            height: 46px;

            li{
                width: 20%;
                height: 100%;
                line-height: 1;
                text-align: center;

                a{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                a.router-link-active i{
                    font-weight: bold !important;
                }
            }
        }
        
    }
</style>