<template>
    <div>
        <router-view></router-view>
        <Nav />
    </div>
</template>

<script>
    import Nav from '@/components/common/Nav.vue'
    export default {
        components:{
            Nav
        },
    }
</script>

<style>

</style>